import React from "react";

function App() {
  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center relative">
      {/* Decorative Grid */}
      <div className="fixed inset-0 opacity-20 bg-grid-pattern"></div>

      {/* Animated Background Elements */}
      <div className="fixed inset-0 z-0 overflow-hidden">
        <div className="absolute top-10 left-1/4 w-40 h-40 border-4 border-gray-300 rounded-full animate-spin-slow"></div>
        <div className="absolute bottom-10 right-1/4 w-64 h-64 border-4 border-gray-300 rounded-full animate-pulse-slow"></div>
        <div className="absolute top-40 right-1/3 w-72 h-72 border-4 border-gray-300 rounded-full animate-ping-slow"></div>
      </div>

      {/* Centered Container */}
      <div className="p-8 max-w-screen-lg mx-4 md:mx-auto z-10 space-y-8 relative flex items-center justify-center flex-col">
        {/* Top Decorative Elements */}
        <div className="absolute top-0 left-0 w-24 h-24 border-t-4 border-l-4 border-gray-300"></div>
        <div className="absolute top-0 right-0 w-24 h-24 border-t-4 border-r-4 border-gray-300"></div>

        {/* Main Content */}
        <div className="relative text-center px-12 py-16 bg-white bg-opacity-95 backdrop-filter backdrop-blur-lg rounded-xl shadow-2xl">
          <h1 className="text-5xl font-extrabold text-gray-900 mb-4">Página en Construcción</h1>
          <p className="text-xl text-gray-700 mb-8">
            Estamos trabajando arduamente para traerte una experiencia asombrosa. ¡Vuelve pronto!
          </p>

          {/* Informative Decorative Elements */}
          <p className="text-md text-gray-600 mb-4">
            <strong>Equipo:</strong> Arquitectos y desarrolladores expertos
          </p>
          <p className="text-md text-gray-600 mb-4">
            <strong>Visión:</strong> Crear las mejores soluciones inteligentes con IA
          </p>

          {/* Decorative Lines */}
          <div className="absolute top-0 left-0 w-2 h-full bg-gray-300 transform translate-x-[-50%]"></div>
          <div className="absolute top-0 right-0 w-2 h-full bg-gray-300 transform translate-x-[50%]"></div>
          <div className="absolute top-0 left-0 w-full h-2 bg-gray-300 transform translate-y-[-50%]"></div>
          <div className="absolute bottom-0 left-0 w-full h-2 bg-gray-300 transform translate-y-[50%]"></div>

          {/* Contact Info */}
          <div className="border-t border-gray-300 mt-8 pt-8">
            <p className="text-lg text-gray-600 mb-2">Contacta con nosotros:</p>
            <p className="text-gray-600">ceo@vanguardinnov.com</p>
          </div>
        </div>

        {/* Bottom Decorative Elements */}
        <div className="absolute bottom-0 left-0 w-24 h-24 border-b-4 border-l-4 border-gray-300"></div>
        <div className="absolute bottom-0 right-0 w-24 h-24 border-b-4 border-r-4 border-gray-300"></div>
      </div>
    </div>
  );
}

export default App;
